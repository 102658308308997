import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loggedToken: '',
    isLogin: false,
    mobile: '',
    name: '',
    email: '',
    emailUpdates: 1,
    twoFctor: 0,
    whatsapp: 1,
    msgType: 0,
    currentSession: {
        twoFctored: 0,
    }
}

export const accountAdminData = createSlice({
    name: 'accountAdminData',
    initialState,
    reducers: {
        UpdateAccountData: (state, newData) => {
            switch (newData.payload.forType) {
                case 'other':
                    Object.keys(newData.payload).forEach((key) => {
                        state.other[key] = newData.payload[key]
                    })
                    break
                default:
                    Object.keys(newData.payload).forEach((key) => {
                        state[key] = newData.payload[key]
                    })
                    break
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { UpdateAccountData } = accountAdminData.actions

export default accountAdminData.reducer
